import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStethoscope, faShieldAlt, faMedkit } from "@fortawesome/free-solid-svg-icons";
import exampleImage from "../assets/whychooseus.png";

const WhyChooseUs = () => {
  return (
    <section className="container page-section-margin bg-white" id="why-choose-us">
      <div className="max-w-6xl mx-auto flex flex-col lg:flex-row lg:items-stretch gap-2 lg:gap-12">
        <h2 className="text-3xl font-extrabold mb-10 lg:hidden">Why Choose Us</h2>

        <div className="w-full flex lg:hidden mb-8">
          <img
            src={exampleImage}
            alt="Healthcare professional writing notes"
            className="rounded-lg w-full object-cover h-[300px]"
          />
        </div>

        <div className="w-full lg:w-1/2">
          <h2 className="hidden lg:block text-3xl font-extrabold mb-10">Why Choose Us</h2>

          <div className="flex flex-col md:flex-row md:text-left md:items-start mb-8">
            <div className="bg-green-500 p-4 rounded-lg flex items-center justify-center w-16 h-16 mb-4 md:mb-0 md:mr-6">
              <FontAwesomeIcon icon={faStethoscope} className="text-white text-3xl" />
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Expert Clinical Engineering Services</h3>
              <p className="text-sm text-gray-400 leading-relaxed">
                At AMETT Group, we ensure your healthcare equipment performs at its optimum, improve patient care and
                enhance operational efficiency.
              </p>
            </div>
          </div>

          <div className="flex flex-col  md:flex-row md:text-left md:items-start mb-8">
            <div className="bg-green-500 p-4 rounded-lg flex items-center justify-center w-16 h-16 mb-4 md:mb-0 md:mr-6">
              <FontAwesomeIcon icon={faShieldAlt} className="text-white text-3xl" />
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Trusted Maintenance Solutions</h3>
              <p className="text-sm text-gray-400 leading-relaxed">
                Join the numerous healthcare facilities that rely on AMETT Group for top-notch medical equipment
                maintenance and clinical engineering services.
              </p>
            </div>
          </div>

          <div className="flex flex-col  md:flex-row md:text-left md:items-start mb-8">
            <div className="bg-green-500 p-4 rounded-lg flex items-center justify-center w-16 h-16 mb-4 md:mb-0 md:mr-6">
              <FontAwesomeIcon icon={faMedkit} className="text-white text-3xl" />
            </div>
            <div>
              <h3 className="text-xl font-bold mb-2">Discover Excellence in Healthcare</h3>
              <p className="text-sm text-gray-400 leading-relaxed">
                Explore our comprehensive solutions today and see how AMETT Group can support your medical equipment
                needs for superior healthcare delivery.
              </p>
            </div>
          </div>
        </div>

        <div className="w-full lg:w-1/2 hidden lg:flex items-stretch">
          <img
            src={exampleImage}
            alt="Healthcare professional writing notes"
            className="rounded-lg w-full object-cover lg:h-[500px]"
          />
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
